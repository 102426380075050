import { createContext, useState, useEffect, useCallback, useMemo } from 'react'

import add from 'date-fns/add'
import throttle from 'lodash.throttle'

import { deleteMessagesBookmark, postMessagesBookmark, getChatChannels } from '../api'
import { useAppUser } from '../app/hooks'

import {
  CAN_BE_ADMIN,
  CAN_BE_CERTIFIED,
  CAN_BE_NAVIGATOR,
  CAN_BE_SUPERUSER,
  HTTP_TOO_MANY_REQUESTS
} from '../arch/constants'

import { chatClient } from '../auth'
import { parseDateOrNull, getHookPromiseErrorHandler, defaultName } from '../common'
import { debouncePromise } from '../debounce'
import { trackChatBookmarked } from '../tracking/segment'

const CAN_BE_CHAT_ADMIN = [...CAN_BE_NAVIGATOR, ...CAN_BE_ADMIN, ...CAN_BE_SUPERUSER, ...CAN_BE_CERTIFIED]

const DEFAULT_LIMIT = 20
const TARGET_LIMIT = 100
const UNREAD_MAXIMUM = 3

const STATUS_UPDATING = 'updating'
const STATUS_LOADING = 'loading'
const STATUS_LOADING_MORE = 'loading more'
const STATUS_LOADED = 'loaded'
const STATUS_SENDING_MESSAGE = 'posting'

const STATUSES = [STATUS_UPDATING, STATUS_LOADING, STATUS_LOADING_MORE, STATUS_LOADED, STATUS_SENDING_MESSAGE]

const EVENT_NEW = 'message.new'
const EVENT_NOTIFICATION_NEW = 'notification.message_new'
const EVENT_NEW_ALL = [EVENT_NEW, EVENT_NOTIFICATION_NEW]

const EVENT_READ = 'message.read'
const EVENT_NOTIFICATION_READ = 'notification.mark_read'
const EVENT_READ_ALL = [EVENT_READ, EVENT_NOTIFICATION_READ]

const EVENT_DELETED = 'message.deleted'
const EVENT_DELETED_ALL = [EVENT_DELETED]

const TYPE_DELETED = 'deleted'

const isEventType = (types, type) => types.includes(type)
const isNewEvent = type => isEventType(EVENT_NEW_ALL, type)
const isReadEvent = type => isEventType(EVENT_READ_ALL, type)
const isDeletedEvent = type => isEventType(EVENT_DELETED_ALL, type)

/**
 * The external interface: this is what the chat service context encapsulates.
 */
const ChatServiceContext = createContext({
  chatIdentifier: '',
  messages: [],
  status: STATUS_LOADING,
  messagesComplete: false,
  messageScrollTarget: { unconditional: false },
  loadMore: () => {},
  sendMessage: (newMessage, setNewMessage, attachments, setAttachments) => {},
  deleteMessage: message => {},
  updateMessage: message => {},
  uploadAttachment: file => {},
  error: null
})

const STORAGE_KEY = 'drafts'
const TRANSACTION_MODE_READ = 'readonly'
const TRANSACTION_MODE_WRITE = 'readwrite'

const openDB = async () => {
  const request = indexedDB.open('chatDatabase', 1)

  return new Promise((resolve, reject) => {
    request.onerror = () => reject(request.error)
    request.onsuccess = () => resolve(request.result)
    request.onupgradeneeded = event => {
      const db = event.target.result
      if (!db.objectStoreNames.contains(STORAGE_KEY)) {
        const objectStore = db.createObjectStore(STORAGE_KEY, { keyPath: 'key' })
        objectStore.createIndex('key', 'key', { unique: true })
      }
    }
  })
}

const performTransaction = async (storeName, mode, operation) => {
  const db = await openDB()
  const transaction = db.transaction(storeName, mode)
  const objectStore = transaction.objectStore(storeName)

  const result = await operation(objectStore)

  await new Promise((resolve, reject) => {
    transaction.oncomplete = () => resolve()
    transaction.onerror = () => reject(transaction.error)
  })

  return result
}

const clearAllDrafts = () =>
  performTransaction(STORAGE_KEY, TRANSACTION_MODE_WRITE, async objectStore => {
    await objectStore.clear()
  })

const getDrafts = async () =>
  performTransaction(STORAGE_KEY, TRANSACTION_MODE_READ, async objectStore => {
    const request = objectStore.getAll()
    const result = await new Promise((resolve, reject) => {
      request.onsuccess = () => resolve(request.result)
      request.onerror = () => reject(request.error)
    })

    return result.reduce((acc, draft) => {
      acc[draft.key] = draft.value
      return acc
    }, {})
  })

const getDraft = async key => {
  const drafts = await getDrafts()
  return drafts[key] ?? {}
}

const removeDraft = key =>
  performTransaction(STORAGE_KEY, TRANSACTION_MODE_WRITE, async objectStore => {
    await objectStore.delete(key)
  })

const updateDraft = (key, value) =>
  performTransaction(STORAGE_KEY, TRANSACTION_MODE_WRITE, async objectStore => {
    if (value) {
      await objectStore.put({ key, value })
    } else {
      await objectStore.delete(key)
    }
  })

/**
 * Adapter to convert messages from Stream Chat into the internal “canonical” message object.
 */
class StreamMessage {
  constructor(original, appUser, additionalProperties) {
    Object.keys(original).forEach(property => {
      this[property] = original[property]
    })

    const { created_at, text, user, editedAt, bookmarkedUserIds } = this

    const { username } = appUser

    this.authorId = user.id
    this.authorName = user.name
    this.body = { text }
    this.createdAt = typeof created_at === 'string' ? parseDateOrNull(created_at) : created_at
    this.updatedAt = typeof editedAt === 'string' ? parseDateOrNull(editedAt) : editedAt
    this.mine = username === user.id
    this.isBookmarkedByUser = bookmarkedUserIds?.includes(username)

    if (additionalProperties) {
      Object.keys(additionalProperties).forEach(property => {
        this[property] = additionalProperties[property]
      })
    }
  }
}

// Adapted from original Thread.displayMessages.
const appendMessages = (
  messages,
  messageLimit,
  cursor,
  messageAdapter,
  setStatus,
  setCursor,
  setMessages,
  setMessagesComplete,
  scrollTargetOptions // { setMessageScrollTarget: state setter function, unconditional: boolean }
) => {
  const newMessages = messages.filter(message => message.type !== TYPE_DELETED).map(messageAdapter)

  setStatus(STATUS_LOADED)
  setMessages(messages => (messages ? [...newMessages, ...messages] : newMessages))
  setCursor(cursor)
  setMessagesComplete(!cursor || messages.length < messageLimit)

  // After messages load, scroll the last one into view (Slack-style) if a setter was supplied.
  // (if the caller doesn’t want to scroll, they would withhold the setter function)
  const { setMessageScrollTarget, unconditional, target } = scrollTargetOptions || {}
  if (messages.length > 0 && setMessageScrollTarget) {
    setMessageScrollTarget({ id: target || messages[messages.length - 1].id, unconditional })
  }
}

const appendOneMessage = (newMessage, setMessages) => {
  // Make sure that we aren’t appending a message that isn’t already there.
  setMessages(messages => {
    const existingMessage = messages.find(currentMessage => currentMessage.id === newMessage.id)
    return existingMessage ? messages : [...messages, newMessage]
  })
}

const MARK_READ_DELAY = 500
const WATCH_UPDATE_DELAY = 1000

const QUERY_RETRY_LIMIT = 5
const QUERY_RETRY_DELAY_RANGE = 2000
const QUERY_RETRY_DELAY_MINIMUM = 1500

const SEND_RETRY_LIMIT = 5
const SEND_RETRY_DELAY_RANGE = 1000
const SEND_RETRY_DELAY_MINIMUM = 1000

const WATCH_RETRY_LIMIT = 4
const WATCH_RETRY_DELAY_RANGE = 1000
const WATCH_RETRY_DELAY_MINIMUM = 250

const RATE_LIMIT_DELAY_RANGE = 30000
const RATE_LIMIT_DELAY_MINIMUM = 75000

// This is actually `_countMessageAsUnread` from the channel prototype, but because it is marked as private,
// we choose to derive the logic here.
const countForNotification = (message, chatClient) =>
  !(
    message.shadowed ||
    message.silent ||
    message.type === TYPE_DELETED ||
    message.user?.id === chatClient.userID ||
    (message.user?.id && chatClient.userMuteStatus(message.user.id))
  )

const delayPromise = async delay =>
  // Thank you https://javascript.info/task/delay-promise
  await new Promise(resolve => setTimeout(resolve, delay))

const requestWithRetry = async (
  request,
  message,
  retryDelayMinimum,
  retryDelayRange,
  retryLimit,
  options = {},
  retryAttempt
) => {
  try {
    return await request()
  } catch (error) {
    const rateLimited = error.response && error.response.status === HTTP_TOO_MANY_REQUESTS
    const timedOut = error.code === 'ECONNABORTED' && error.message.includes('timeout')

    if (!timedOut && !rateLimited) {
      // The only errors that are worth retrying are timeouts and rate limits; everything else will be viewed
      // as a genuine error and just re-thrown.
      throw error
    }

    const { ignoreTimeout } = options
    if (ignoreTimeout && timedOut) {
      return { timeout: true }
    }

    // Do a console.warn so that we can see when a retry was needed.
    const currentAttempt = retryAttempt === undefined ? 1 : retryAttempt
    const nextAttempt = currentAttempt + 1

    console.warn(error, `For ${message} on attempt ${currentAttempt} of ${retryLimit}`)

    if (nextAttempt > retryLimit) {
      // Report when we give up and actually throw the error for maximum loudness.
      console.error(
        [
          `Giving up on ${message} after ${currentAttempt} attempts`,
          'Application state may be out of sync. Most recent error is:'
        ].join('\n'),
        error
      )

      throw error
    } else {
      const delayRange = rateLimited ? RATE_LIMIT_DELAY_RANGE : retryDelayRange
      const delayMinimum = rateLimited ? RATE_LIMIT_DELAY_MINIMUM : retryDelayMinimum

      // Thank you https://javascript.info/task/delay-promise
      await delayPromise(delayRange * Math.random() + delayMinimum)
      return await requestWithRetry(request, message, delayMinimum, delayRange, retryLimit, options, nextAttempt)
    }
  }
}

const queryWithRetry = async (channel, queryOptions, reason) =>
  requestWithRetry(
    async () => await channel.query(queryOptions),
    `channel.query ${reason} ${channel.id}`,
    QUERY_RETRY_DELAY_MINIMUM,
    QUERY_RETRY_DELAY_RANGE,
    QUERY_RETRY_LIMIT
  )

const sendWithRetry = async (channel, message) =>
  requestWithRetry(
    async () => await channel.sendMessage(message),
    `channel.sendMessage ${channel.id}`,
    SEND_RETRY_DELAY_MINIMUM,
    SEND_RETRY_DELAY_RANGE,
    SEND_RETRY_LIMIT,
    { ignoreTimeout: true }
  )

const watchWithRetry = async (channel, messageLimit) =>
  requestWithRetry(
    async () => await channel.watch(),
    `channel.watch ${channel.id}`,
    WATCH_RETRY_DELAY_MINIMUM,
    WATCH_RETRY_DELAY_RANGE,
    WATCH_RETRY_LIMIT
  )

const retrieveStreamMessagesUpToTarget = async (channel, channelState, targetMessageId) => {
  const { messages } = channelState
  const targetMessage = messages.find(message => message.id === targetMessageId)
  if (!targetMessage && messages.length > 0) {
    const cursor = messages[0].id

    const updatedState = await queryWithRetry(
      channel,
      {
        messages: { limit: TARGET_LIMIT, id_lt: cursor }
      },
      `looking for ${targetMessageId}`
    )

    const { messages: updatedMessages } = updatedState
    channelState.messages = [...updatedMessages, ...messages]

    if (updatedMessages.length >= TARGET_LIMIT) {
      // It’s only worthwhile to try again if there might be more messages out there.
      // If we’re tapped out, then we’re done regardless, whether or not the target
      // message is present in the array.
      await retrieveStreamMessagesUpToTarget(channel, channelState, targetMessageId)
    }
  }
}

const streamMessageAdapter = (appUser, lastRead, chatClient) => message =>
  new StreamMessage(
    message,
    appUser,
    lastRead && chatClient
      ? {
          unread: message.created_at > lastRead && countForNotification(message, chatClient)
        }
      : undefined
  )

const useStreamService = (channel, messageLimit = DEFAULT_LIMIT, targetMessageId) => {
  const appUser = useAppUser()

  const [status, setStatus] = useState(STATUS_LOADING)
  const [error, setError] = useState(null)

  const [cursor, setCursor] = useState(null)
  const [messages, setMessages] = useState(null)
  const [messagesComplete, setMessagesComplete] = useState(null)
  const [messageScrollTarget, setMessageScrollTarget] = useState({ unconditional: true })
  const [unreadCount, setUnreadCount] = useState(0)
  const [unreadStatus, setUnreadStatus] = useState([])

  const messageRetrievalErrorHandler = useMemo(
    () =>
      getHookPromiseErrorHandler(setError, [
        [setMessages, null],
        [setCursor, null],
        [setMessagesComplete, false],
        [setStatus, STATUS_LOADED]
      ]),
    []
  )

  const deleteMessage = useCallback(message => {
    // Double safety net.
    if (!message?.id) {
      return
    }

    chatClient().deleteMessage(message.id)
  }, [])

  const removeBookmark = async messageId => {
    const deleteResponse = await deleteMessagesBookmark(messageId)

    updateMessages(deleteResponse.message)
  }

  const addBookmark = async messageId => {
    const updateResponse = await postMessagesBookmark(messageId)
    trackChatBookmarked(messageId)

    updateMessages(updateResponse.message)
  }

  const updateMessage = async updatedMessage => {
    // Double safety net.
    if (!updatedMessage?.id) {
      return
    }

    const updateResponse = await chatClient().updateMessage(updatedMessage)

    updateMessages(updateResponse.message)
  }

  const updateMessages = updatedMessage => {
    setMessages(messages => {
      const indexToUpdate = messages.findIndex(currentMessage => currentMessage.id === updatedMessage.id)
      return indexToUpdate === -1
        ? messages
        : [
            ...messages.slice(0, indexToUpdate),
            new StreamMessage(updatedMessage, appUser),
            ...messages.slice(indexToUpdate + 1)
          ]
    })
  }

  // mark-read logic adapted from stream-chat-react Channel code.
  const markRead = useCallback(() => {
    if (channel.disconnected || !channel.getConfig()?.read_events) {
      return
    }

    // Marking as read also means removing all in-memory unread properties.
    setMessages(messages =>
      messages?.map(message => {
        if (message.unread) {
          message.unread = false
        }

        return message
      })
    )

    channel.markRead()
  }, [channel])

  const markReadThrottled = useMemo(
    () => throttle(markRead, MARK_READ_DELAY, { leading: true, trailing: true }),
    [markRead]
  )

  // Article on how throttle and debounce are different:
  // https://css-tricks.com/debouncing-throttling-explained-examples/
  //
  // We throttle markRead because we don’t want to drop any attempts to mark as read.
  // We debounce watchUpdate because when multiple updates happen, we only care about the latest one.
  const watchUpdateDebounced = useMemo(
    () => debouncePromise(async () => await channel.watch(), WATCH_UPDATE_DELAY),
    [channel]
  )

  const chatEventHandler = useCallback(
    event => {
      const { type } = event
      if (isNewEvent(type)) {
        const { message } = event
        const newMessage = new StreamMessage(message, appUser)
        newMessage.unread = true
        appendOneMessage(newMessage, setMessages)
        setMessageScrollTarget({ id: message.id })
      } else if (isDeletedEvent(type)) {
        const { message } = event
        setMessages(messages => {
          const indexToDelete = messages.findIndex(currentMessage => currentMessage.id === message.id)
          return indexToDelete === -1
            ? messages
            : [...messages.slice(0, indexToDelete), ...messages.slice(indexToDelete + 1)]
        })
      }

      if (isNewEvent(type) || isReadEvent(type)) {
        const updateWatchState = async () => {
          // Avoid the retry here; not important enough.
          try {
            const watchState = await watchUpdateDebounced()
            const { read } = watchState
            setUnreadStatus(read)

            const userUnread = read?.find(status => status.user.id === appUser?.username)
            if (userUnread) {
              setUnreadCount(userUnread.unread_messages)
            }
          } catch (error) {
            // No-op—not a huge priority.
          }
        }

        updateWatchState()
      }
    },
    [appUser, watchUpdateDebounced]
  )

  const loadMore = useCallback(async () => {
    // stopHighlightingIfApplicable()
    setStatus(STATUS_LOADING_MORE)

    try {
      // For Load More it’s safe to say that the channel was last read just now.
      const lastRead = new Date().toISOString()

      const channelState = await queryWithRetry(
        channel,
        {
          // We ask for one over the page limit just in case message count % limit === 0.
          messages: { limit: messageLimit + 1, id_lt: cursor }
        },
        'load more'
      )

      const { messages } = channelState
      if (!messages || messages.length === 0) {
        // Edge case for when the last loaded page was exactly the last set of messages.
        setStatus(STATUS_LOADED)
        setMessagesComplete(true)
        return
      }

      const currentChatClient = chatClient()
      const oneOver = messages.length > messageLimit // See comment in query above.
      appendMessages(
        messages.slice(oneOver ? 1 : 0),
        messageLimit + (oneOver ? 0 : 1),
        oneOver ? messages[1]?.id : null,
        streamMessageAdapter(appUser, lastRead, currentChatClient),
        setStatus,
        setCursor,
        setMessages,
        setMessagesComplete
      )
    } catch (error) {
      messageRetrievalErrorHandler(error)
    }
  }, [channel, appUser, cursor, messageLimit, messageRetrievalErrorHandler])

  const sendMessage = useCallback(
    async (newMessage, setNewMessage, attachments, setAttachments) => {
      // stopHighlightingIfApplicable()
      setStatus(STATUS_SENDING_MESSAGE)

      try {
        const sendResult = await sendWithRetry(channel, { text: newMessage, attachments })
        const { message, timeout } = sendResult

        if (message && !timeout) {
          appendOneMessage(new StreamMessage(message, appUser), setMessages)
          setMessageScrollTarget({ id: message.id, unconditional: true })
        }

        setNewMessage('')
        setAttachments([])
        setStatus(STATUS_LOADED)
      } catch (error) {
        // Special handling of a send error—we create a placeholder message with the error in it.
        setMessages(messages => [
          ...(messages || []),
          {
            error,
            authorId: appUser.username,
            authorName: appUser.name || defaultName(appUser.nickname, appUser.familyName),
            body: { isRichText: false, text: newMessage },
            createdAt: new Date(),
            id: Date.now(),
            text: newMessage,
            mine: true
          }
        ])

        setStatus(STATUS_LOADED)
        return
      }
    },
    [channel, appUser]
  )

  const uploadAttachment = useCallback(
    async file => {
      const { name, size, type } = file
      const isImage = type.startsWith('image')
      const isVideo = type.startsWith('video')

      const uploadResponse = await channel[isImage ? 'sendImage' : 'sendFile'](file)
      const { file: uploadedFile } = uploadResponse
      return {
        // Currently only accept images and video; more types will require expansion of the type logic.
        type: isImage ? 'image' : isVideo ? 'media' : 'file',
        title: name,
        file_size: size,
        mime_type: type,
        thumb_url: uploadedFile,
        asset_url: uploadedFile
      }
    },
    [channel]
  )

  useEffect(() => {
    if (channel && appUser) {
      let active = true

      setStatus(STATUS_LOADING)
      setMessages(null)

      const initializeMessages = async () => {
        try {
          // If we have been given a target message ID, we keep loading until we hit it.
          const watchState = await watchWithRetry(channel, messageLimit)

          if (targetMessageId) {
            await retrieveStreamMessagesUpToTarget(channel, watchState, targetMessageId)
          }

          const currentChatClient = chatClient()
          const lastRead =
            channel.countUnread() > 0
              ? (() => {
                  const read = watchState.read?.find(readState => readState.user.id === currentChatClient.userID)
                  return read?.last_read
                })()
              : null

          if (active) {
            const { messages: rawMessages, read } = watchState

            const messageCountToAppend = targetMessageId
              ? (() => {
                  // If we have a target message, we show _up to_ the target message or the message limit, whichever
                  // is larger.
                  const targetMessageIndex = rawMessages.findIndex(message => message.id === targetMessageId)
                  return targetMessageIndex === -1
                    ? messageLimit
                    : Math.max(messageLimit, rawMessages.length - targetMessageIndex)
                })()
              : messageLimit

            const moreAvailable = messageCountToAppend < rawMessages.length
            const messages = rawMessages.reverse().slice(0, messageCountToAppend).reverse()
            // ^^^^^ Double-reverse trick to get the most recent messages.

            appendMessages(
              // For some reason, the API doesn’t permit pagination without a cursor, so there is some waste
              // here with getting messages without any limits but imposing the limit in memory.
              messages,
              messageCountToAppend + (moreAvailable ? 0 : 1),
              messages[0]?.id,
              streamMessageAdapter(appUser, lastRead, currentChatClient),
              setStatus,
              setCursor,
              setMessages,
              setMessagesComplete,
              { setMessageScrollTarget, unconditional: true, target: targetMessageId }
            )
            // setNewMessage('')
            setUnreadCount(channel.countUnread())
            setUnreadStatus(read)

            channel.on(chatEventHandler)
          }
        } catch (error) {
          if (active) {
            messageRetrievalErrorHandler(error)
          }
        }
      }

      initializeMessages()

      return () => {
        active = false
        channel.off(chatEventHandler)
      }
    } else {
      setStatus(STATUS_LOADED)
    }
  }, [channel, appUser, targetMessageId, markRead, messageRetrievalErrorHandler, chatEventHandler, messageLimit])

  return {
    chatIdentifier: channel?.id,
    deleteMessage,
    updateMessage,
    addBookmark,
    removeBookmark,
    messages,
    messagesComplete,
    messageScrollTarget,
    loadMore,
    markRead: markReadThrottled,
    newMessagesAvailable: Boolean(unreadCount),
    sendMessage,
    unreadCount,
    unreadStatus,
    uploadAttachment,
    status,
    error
  }
}

const CHANNEL_LIMIT = 30
const MESSAGE_FEED_DURATION = 48
const NOTIFICATION_WATCH_DELAY = 1500

const createChannelSpec = (chatClient, householdId, goalId) => ({
  householdId,
  channel: chatClient.channel('messaging', goalId || householdId)
})

const createChannelSpecList = (appUser, chatClient, chatChannels) =>
  chatChannels
    .filter(channel => appUser.role(CAN_BE_CHAT_ADMIN) || !channel?.goalId)
    .map(channel => {
      const { householdId, goalId } = channel

      return createChannelSpec(chatClient, householdId, goalId)
    })

const createChannelIdList = channelSpecList => channelSpecList.map(channelSpec => channelSpec.channel.id)

const createMessagePreview = (message, channelId, householdId, appUser) =>
  new StreamMessage({ ...message, channelId, householdId }, appUser)

const extractMessagePreviews = (messages, cutoff, chatClient, appUser, channelId, householdId) => {
  const previewableMessages = messages.filter(
    message => message.created_at > cutoff && countForNotification(message, chatClient)
  )

  return previewableMessages.map(message => createMessagePreview(message, channelId, householdId, appUser))
}

const SPECIAL_X_FOUNDING_YEAR = 117 // Date.getYear() is relative to 1900.

const setupPreviews = (chatClient, appUser, channel, watchState, householdId) => {
  const { messages, read } = watchState

  const userReadState = read[chatClient.userID]
  if (userReadState) {
    const {
      last_read: lastRead,
      last_read_message_id: lastReadMessageId,
      unread_messages: unreadMessages
    } = userReadState

    if (unreadMessages === 0) {
      return []
    }

    if (lastReadMessageId) {
      const cutoffMessage = messages.find(message => message.id === lastReadMessageId)
      const cutoff = cutoffMessage?.created_at

      return extractMessagePreviews(messages, cutoff, chatClient, appUser, channel.id, householdId)
    }

    return !lastRead || lastRead.getYear() < SPECIAL_X_FOUNDING_YEAR
      ? []
      : extractMessagePreviews(messages, lastRead, chatClient, appUser, channel.id, householdId)
  } else {
    return []
  }
}

const setupFeed = (cutoff, chatClient, appUser, channel, watchState, householdId) =>
  extractMessagePreviews(watchState.messages, cutoff, chatClient, appUser, channel.id, householdId)

// We don’t use the useAppUser hook because the notifications hook is used outside of the app user context.
const useStreamNotifications = (chatClient, appUser) => {
  const [chatChannels, setChatChannels] = useState()
  const [messagePreviews, setMessagePreviews] = useState([])
  const [waitingForUnreadMessages, setWaitingForUnreadMessages] = useState(true)

  // The `useCallback`s are needed to avoid repeated updates to context consumers that modify the message preview
  // array within`useEffect` functions.
  const addMessagePreviews = useCallback(messagesWithChannel => {
    return setMessagePreviews(messagePreviews => [
      ...messagePreviews,

      // Ensure that we only add messages that aren’t already among the previews (based on ID).
      ...(messagesWithChannel || []).filter(
        newMessage => !messagePreviews.find(existingMessage => existingMessage.id === newMessage.id)
      )
    ])
  }, [])

  const removeMessagePreviewsForChannel = useCallback(
    channelId =>
      // TODO channelId is a workaround: Stream Chat doesn’t identify the read message in its
      //      `message.read` notifications.
      setMessagePreviews(messagePreviews => messagePreviews.filter(message => message.channelId !== channelId)),
    []
  )

  const queryChannels = useCallback(
    async messageMapper => {
      // Falsy means that the hook is still loading so it isn’t useful to query channels yet.
      if (!chatChannels) {
        return null
      }

      const { goalChatChannels, householdChannels } = chatChannels ?? {}
      const channels = createChannelSpecList(appUser, chatClient, [
        ...(goalChatChannels ?? []),
        ...(householdChannels ?? [])
      ])
      const channelIds = createChannelIdList(channels)
      if (channelIds.length === 0) {
        return []
      }

      // We create the request function this way (rather than writing the function inline in `requestWithRetry`)
      // so that the offset variable inside the request isn’t considered “unsafe” by the linter.
      const channelQuery = offset => async () =>
        await chatClient.queryChannels(
          {
            type: 'messaging',
            id: { $in: channelIds },
            members: { $in: [appUser.username] }
          },
          { last_message_at: -1 },
          { state: true, watch: true, limit: CHANNEL_LIMIT, offset, message_limit: TARGET_LIMIT }
        )

      const allChannels = {}
      let offset = 0

      while (Object.keys(allChannels).length < channelIds.length) {
        const channelPage = await requestWithRetry(
          channelQuery(offset),
          `queryChannels initialize offset ${offset}`,
          WATCH_RETRY_DELAY_MINIMUM,
          WATCH_RETRY_DELAY_RANGE,
          WATCH_RETRY_LIMIT
        )

        channelPage.forEach(channel => (allChannels[channel.id] = channel))
        if (channelPage.length < CHANNEL_LIMIT) {
          break // If the latest page is less than the limit, nothing else will show up so we can stop here too.
        } else {
          offset += channelPage.length
          await delayPromise(NOTIFICATION_WATCH_DELAY)
        }
      }

      const messages = channels.reduce((messageFeed, channelSpec) => {
        const { householdId, channel } = channelSpec

        // Sometimes Undivided will ask for a channel that the user cannot see—specifically, a household for which
        // they are on a goal but not a navigator—so we still have to make a final check on whether we requested a
        // channel that did not come back in the query.
        const watchChannel = allChannels[channel.id]
        const watchState = watchChannel?.state
        return watchState
          ? [...messageFeed, ...messageMapper(chatClient, appUser, watchChannel, watchState, householdId)]
          : messageFeed
      }, [])

      return messages.sort((left, right) => right.created_at - left.created_at) // Reverse chronological.
    },
    [appUser, chatClient, chatChannels]
  )

  useEffect(() => {
    // A new `appUser` (falsy or otherwise) must clear the preview list.
    setMessagePreviews([])

    // No `appUser` nor email confirmation means no chat channels.
    if (!appUser?.emailConfirmedAt) {
      setChatChannels({ goalChatChannels: [], householdChannels: [] })
      return
    }

    let active = true

    const fetchChatChannels = async () => {
      try {
        const chatChannelResponse = await getChatChannels()
        if (!active) {
          return
        }

        setChatChannels(chatChannelResponse)
      } catch (error) {
        setChatChannels(null)
      }
    }

    fetchChatChannels()
    return () => {
      active = false
    }
  }, [appUser])

  const queryMessageFeed = useCallback(async () => {
    const queryTime = new Date()
    const cutoff = add(queryTime, { hours: -MESSAGE_FEED_DURATION })

    const messages = await queryChannels((chatClient, appUser, watchChannel, watchState, householdId) =>
      setupFeed(cutoff, chatClient, appUser, watchChannel, watchState, householdId)
    )

    // Falsy `messages` means we aren’t completely done loading yet.
    return messages ? { queryTime, messages } : null
  }, [queryChannels])

  useEffect(() => {
    if (!appUser || !chatClient?.wsConnection?.connectionID) {
      return
    }

    let active = true

    const chatEventHandler = event => {
      const { channel_id: channelId, message, type, user } = event
      if (isNewEvent(type) || isReadEvent(type)) {
        if (isNewEvent(type) && countForNotification(message, chatClient)) {
          const { goalChatChannels, householdChannels } = chatChannels ?? {}
          const allChannels = [...(goalChatChannels ?? []), ...(householdChannels ?? [])]

          const channelDetails = allChannels.find(channel => channel.id === channelId)
          if (channelDetails) {
            addMessagePreviews([createMessagePreview(message, channelId, channelDetails.householdId, appUser)])
          }
        } else if (isReadEvent(type) && user?.id === chatClient.userID) {
          removeMessagePreviewsForChannel(channelId)
        }
      }
    }

    const queryAndWatch = async () => {
      const unreadMessages = await queryChannels(setupPreviews)
      if (active) {
        addMessagePreviews(unreadMessages)
        setWaitingForUnreadMessages(false)
      }
    }

    setWaitingForUnreadMessages(true)
    queryAndWatch()
    chatClient.on(chatEventHandler)

    return () => {
      active = false
      chatClient.off(chatEventHandler)
    }
  }, [appUser, chatClient, addMessagePreviews, removeMessagePreviewsForChannel, queryChannels, chatChannels])

  return {
    messagePreviews,
    addMessagePreviews,
    queryMessageFeed,
    removeMessagePreviewsForChannel,
    setMessagePreviews,
    waitingForUnreadMessages,
    totalUnreadMessages: messagePreviews.length
  }
}

export {
  STATUS_LOADED,
  STATUS_LOADING,
  STATUS_LOADING_MORE,
  STATUS_SENDING_MESSAGE,
  STATUS_UPDATING,
  STATUSES,
  MESSAGE_FEED_DURATION,
  UNREAD_MAXIMUM,
  ChatServiceContext,
  useStreamService,
  useStreamNotifications,
  StreamMessage,
  updateDraft,
  removeDraft,
  getDraft,
  clearAllDrafts
}
